<template>
  <ul
    class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
    id="accordionSidebar"
  >
    <!-- Sidebar - Brand -->
    <router-link
      class="sidebar-brand d-flex align-items-center justify-content-center"
      :to="{ name: 'moderator.home' }"
    >
      <img src="/logo.png" alt="logo">
    </router-link>

    <!-- Divider -->
    <hr class="sidebar-divider my-0" />

    <!-- Nav Item - Dashboard -->
    <li class="nav-item">
      <router-link class="nav-link" :to="{ name: 'moderator.home' }">
        <i class="fas fa-fw fa-dashboard"></i>
        <span>Dashboard</span></router-link
      >
    </li>

    <!-- Divider -->
    <hr class="sidebar-divider" />

    <!-- Heading -->
    <div class="sidebar-heading">Content</div>
    <li class="nav-item">
      <router-link class="nav-link" :to="{ name: 'moderator.categories.index' }">
        <i class="fas fa-fw fa-chart-area"></i>
        <span>Categories</span></router-link
      >
    </li>
    <li class="nav-item">
      <router-link class="nav-link" :to="{ name: 'moderator.discussions.index' }">
        <i class="fas fa-fw fa-chart-area"></i>
        <span>Discussions</span></router-link
      >
    </li>
    <li class="nav-item">
      <router-link class="nav-link" :to="{ name: 'moderator.conference.index' }">
        <i class="fas fa-fw fa-chart-area"></i>
        <span>Daily Conference</span></router-link
      >
    </li>
    <hr class="sidebar-divider">
    <div class="sidebar-heading">Learning</div>
    <li class="nav-item">
      <router-link class="nav-link" :to="{ name: 'moderator.academy.index' }">
        <i class="fas fa-fw fa-chart-area"></i>
        <span>Academy</span></router-link
      >
    </li>
    <li class="nav-item">
      <router-link class="nav-link" :to="{ name: 'moderator.elite-videos.index' }">
        <i class="fas fa-fw fa-chart-area"></i>
        <span>Elite Videos</span></router-link
      >
    </li>
    <li class="nav-item">
      <router-link class="nav-link" :to="{ name: 'moderator.correlation.index' }">
        <i class="fas fa-fw fa-chart-area"></i>
        <span>Correlation Analysis</span></router-link
      >
    </li>

    <!-- Divider -->
    <hr class="sidebar-divider d-none d-md-block" />

    <!-- Sidebar Toggler (Sidebar) -->
    <div class="text-center d-none d-md-inline">
      <button class="rounded-circle border-0" id="sidebarToggle"></button>
    </div>
  </ul>
</template>

<script>
export default {
  methods: {
    logout() {
      this.$axios.post("/api/v1/logout").then(() => {
        this.$auth.removeToken();
        window.location.href = this.$router.resolve({ name: "login" }).href;
      });
    },
  },
};
</script>